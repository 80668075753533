import {Injectable, SecurityContext} from "@angular/core";
import {HttpBackend, HttpClient, HttpEvent, HttpResponse} from "@angular/common/http";
import {Observable, of, switchMap, throwError} from "rxjs";
import {AcHTTPResponse} from "../../../core/http/http-response.models";
import {Enveloppe, EnveloppeFichier, EnveloppePieceJointe, InfosEnveloppe} from "./enveloppe.models";
import {api} from "../../../core/api/api.vars";
import {environment} from "../../../../environments/environment";
import {catchError, filter, map, share, take, tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {selectEnveloppeFichiers, selectEnveloppeInfos, selectEnveloppeSession} from "./enveloppe.selectors";
import {DocumentsService} from "../../../shared/documents/documents.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AlertDialogComponent} from "@alpes-controles/ui-lib";
import {MatDialog} from "@angular/material/dialog";
import {
  EnveloppeErrorFinalValidationModalComponent
} from "../components/enveloppe-error-final-validation-modal/enveloppe-error-final-validation-modal.component";
import {NotificationService} from "../../../core/notifications/notification.service";
import {setMaintenanceEnveloppe} from "./enveloppe.actions";

@Injectable({
  providedIn: 'root'
})
export class EnveloppeService {

  private httpClient: HttpClient;
  constructor(
      private httpBackend: HttpBackend,
      private store: Store,
      private documentService: DocumentsService,
      private sanitizer: DomSanitizer,
      private dialog: MatDialog,
      private notificationService: NotificationService,
  ) {
    this.httpClient = new HttpClient(this.httpBackend)
  }

  /**
   * Récupère les infos de l'enveloppe
   * @param uidDestinataire
   */
  getInfosEnveloppe(uidDestinataire: string): Observable<AcHTTPResponse<InfosEnveloppe>> {
    // On récupère l'éventuel uid de session stocké dans le localstorage
    const sessionUid = localStorage.getItem('session-uid-' + uidDestinataire) ?? null;

    const headers = {};
    if (sessionUid !== null) {
      headers['session-uid'] = sessionUid;
    }

    return this.httpClient
        .get<AcHTTPResponse<InfosEnveloppe>>(
            `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.getInfos}`
                .replace('{uid}', uidDestinataire),
            {
              headers
            }
        )
        .pipe(share());
  }

  setSessionUid(uidSession: string, uidDestinataire: string) {
    localStorage.setItem('session-uid-' + uidDestinataire, uidSession);
  }

  demandeContactEnveloppeExpiree(uid: string, comment: string) {
    return this.httpClient.post(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.demandeContactExpiree}`
            .replace('{uid}', uid),
        {comment}
    )
  }

  /**
   * Lance une requête avec l'uid de session en headers
   * @param method
   * @param url
   * @param data
   * @param parametres
   */
  requestWithSessionUid<T = any>(method: string, url: string, data: any = {}, parametres: any = {}): Observable<T | HttpEvent<T>> {


    return this.store.select(selectEnveloppeSession)
        .pipe(
            take(1),
            switchMap(session => {
              const options = {
                headers: {
                  ...parametres?.headers,
                  "session-uid": session?.uid ?? ''
                },
                responseType: parametres?.responseType ?? 'json',
                observe: parametres?.observe ?? 'body',
              };
              switch (method) {
                case 'get':
                  return this.httpClient.get<T>(
                      url,
                      options
                  );
                case 'post':
                  return this.httpClient.post<T>(
                      url,
                      data,
                      options
                  );
                case 'delete':
                  return this.httpClient.delete<T>(
                      url,
                      options
                  );
              }
            }),
            catchError<any, any>(err => {
              // Erreur 503 - Serveur Agence down
              if(err.status === 503){
                  this.store.dispatch(setMaintenanceEnveloppe({maintenanceEnveloppe: true}));
              }
              return throwError(err);
            })
        )
  }

  /**
   * Envoie une requête post avec l'uid de session en headers
   * @param url
   * @param data
   * @param options
   * @private
   */
  postWithSessionUid<T = any>(url: string, data: any = {}, options: any = {}) {
    return this.requestWithSessionUid('post', url, data, options);
  }
  /**
   * Envoie une requête post avec l'uid de session en headers
   * @param url
   * @param data
   * @param options
   * @private
   */
  patchWithSessionUid<T = any>(url: string, data: any = {}, options: any = {}) {
    return this.requestWithSessionUid<T>('patch', url, data, options);
  }
  /**
   * Envoie une requête delete avec l'uid de session en headers
   * @param url
   * @param data
   * @param options
   * @private
   */
  deleteWithSessionUid<T = any>(url: string, data: any = {}, options: any = {}) {
    return this.requestWithSessionUid<T>('delete', url, data, options);
  }

  /**
   * Envoie une requête get avec l'uid de session en headers
   * @param url
   * @param data
   * @param options
   * @private
   */
  getWithSessionUid<T = any>(url: string, data: any = {}, options: any = {}) {
      return this.requestWithSessionUid('get', url, data, options);
  }

  /**
   * Signale que le fichier est vu
   * @param uid
   * @param fichierId
   */
  setFichierOpened(uid: string, fichierId: number) {
    return this.postWithSessionUid(`${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.fichierVu}`
        .replace('{uid}', uid)
        .replace('{id_fichier}', fichierId.toString()));
  }

  /**
   * Signale que le fichier est lu
   * @param uid
   * @param fichierId
   */
  setFichierRead(uid: string, fichierId: number) {
    return this.postWithSessionUid(`${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.fichierLu}`
        .replace('{uid}', uid)
        .replace('{id_fichier}', fichierId.toString()));
  }

    /**
     * Récupère le contenu d'une pièce jointe
     */
  getContentPieceJointe(uid: string, pieceJointe: EnveloppePieceJointe) {
      const url = this.getPjUrl(pieceJointe, uid),
          options = {
            observe: 'response',
            responseType: 'blob'
          };
      return this.getWithSessionUid<HttpResponse<Blob>>(url, {}, options)
  }

  getFichierUrl(fichier: EnveloppeFichier, uid: string, edite: boolean): string {
    let url = `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.getFichier
      .replace('{id_fichier}', ""+fichier.id)
      .replace('{uid}', uid)

    }`;
    if (edite) {
      url += '?edite=1';
    }
    return url;
  }

  downloadFichierUrl(fichier: EnveloppeFichier, uid: string): string {
    return `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.downloadFichier
        .replace('{id_fichier}', ""+fichier.id)
        .replace('{uid}', uid)

    }`;
  }

  downloadFichier(fichier: EnveloppeFichier, uid: string, edite: boolean): Observable<any> {
    const options = {
      observe: 'response',
      responseType: 'blob'
    };

    let queryParams = "";
    if(edite) {
      queryParams += "?edite=1"
    }

    return this.getWithSessionUid<HttpResponse<Blob>>(this.downloadFichierUrl(fichier, uid) + queryParams, {}, options)
        .pipe(
            tap(response => {
              const contentDisposition = response.headers.get('Content-Disposition');
              const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
              console.log(filename);
              return this.documentService.downloadFile(
                  response.body, response.headers.get('Content-Type'), filename)
            })
        )
  }

  getPjUrl(pj: EnveloppePieceJointe, uid: string) {
    return `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.getPieceJointe}`
        .replace('{uid}', uid)
        .replace('{id_piece_jointe}', pj.id.toString())
  }

  downloadPieceJointe(pieceJointe: EnveloppePieceJointe, uid: string) {
    return this.getContentPieceJointe(uid, pieceJointe)
        .pipe(
            tap(response => this.documentService.downloadFile(
                response.body, response.headers.get('Content-Type'), pieceJointe.fichier))
        )
  }

  downloadAll(uid: string, enveloppe: Enveloppe) {
    const url = `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.downloadAll
        .replace('{uid}', uid)
    }`;

    return this.getWithSessionUid(url, {}, {observe: 'response', 'responseType': 'blob'})
        .pipe(
            tap(response => this.documentService.downloadFile(
                response.body, 'application/zip', this.sanitizer.sanitize(SecurityContext.URL, enveloppe.intitule) + '.zip'))
        )
  }

  updateForms(data: any, uid: string) {
    return this.postWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.updateForms}`.replace('{uid}', uid),
        {forms: data}
    )
  }

  getInfosSiret(siret: string, uid: string) {
    return this.getWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.infosSiret}?siret=${siret}`
            .replace('{uid}', uid),
    )
  }

  getConditionsPaiementSiret(siret: string, uid: string) {
    return this.getWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.conditionPaiement}?siret=${siret}`
            .replace('{uid}', uid),
    )
  }

  addPj(uid: string, file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.postWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.addPj}`
            .replace('{uid}', uid),
        data
    )
  }

  delPj(uid: string, filename: string) {
    return this.deleteWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.delPj}`
            .replace('{uid}', uid) + '?filename=' + filename
    );
  }

  downloadPj(uid: string, filename: string) {
    const url = `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.downloadPj
        .replace('{uid}', uid)
    }`;

    return this.getWithSessionUid(url + '?filename=' + filename, null, {observe: 'response', 'responseType': 'blob'})
        .pipe(
            tap(response => this.documentService.downloadFile(
                response.body, null, filename))
        )
  }

  signe(uid: string) {    
    return this.getWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.signe}`
            .replace('{uid}', uid),
    ).pipe(
        take(1)
    );
  }
  refuse(uid: string, comment: string) {
    return this.postWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.refuse}`
            .replace('{uid}', uid),
      {comment}
    ).pipe(
        take(1)
    );
  }

  retourSaisie(uid: string) {
    return this.getWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.retourSaisie}`
            .replace('{uid}', uid),
    ).pipe(
        take(1)
    );
  }

  getFichiersNonLus(): Observable<EnveloppeFichier[]> {
    return this.store.select(selectEnveloppeFichiers)
        .pipe(map(fichiers => fichiers.filter(fichier => !fichier.lu)));
  }

  sendOtp(uid: string) {
    return this.getWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.sendOtp}`
            .replace('{uid}', uid),
    ).pipe(
        take(1)
    );
  }

  validateOtp(uid: string, code: string) {
    return this.postWithSessionUid(
        `${environment.api.BASE_URL}${environment.api.API_VERSION}${api.endpoints.enveloppe.valideOtp}`
            .replace('{uid}', uid),
        { codeOtp: code }
    ).pipe(
        take(1)
    );
  }
}
